.parentDiv{
    display: flex;
    align-items: center;
}
.logoDiv{
    display: flex;
    align-items: center;
    flex-grow: 1;
}
.logo{
    width: 100px;
    height: auto;
    margin: 10px;
}
.menuDiv{
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
}
.iconsDiv{
    text-align: right;
    flex-grow: 1;
}
.hamburger{
    display: none;
}
.dropdown{
    /* display:none; */
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #1c1a1e;
}
.navbar{
    position: relative;
}

.storyMenuItem{
    font-weight: bold;
    border: 1px solid white;
    padding: 0 15px 0 15px;
    cursor: pointer;
}

.storyMenuItem:hover{
    border-color: #35E5C3;;
    color: #35E5C3;;
}
@media screen and (max-width: 1000px) {
    .menuDiv, .iconsDiv {
        display: none;
    }
    .hamburger{
        display: block;
        text-align: right;
        margin-left: auto;
    }
}
