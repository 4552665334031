body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: 'Courier Prime', monospace;
  color: white;
  font-size: 15px;
}

::selection {
  background: #f40c54;
  color: #ffffff !important;
}

@font-face {
  font-family: 'MonsterMashOutline';
  src: local('MonsterMashOutline'), url(./fonts/CCMonsterMashOutlineW05-Rg.ttf);
}
@font-face {
  font-family: 'MonsterMash';
  src: local('MonsterMash'), url(./fonts/CCMonsterMashW05-Regular.ttf);
}
@font-face {
  font-family: 'MonsterMashWorn';
  src: local('MonsterMashWorn'), url(./fonts/CCMonsterMashWornW05-Rg.ttf);
}

a{
  text-decoration: none;
  color: white;
}


a:hover{
  /* color: #00d084; */
  color: #35E5C3;
}

